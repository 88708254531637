import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { featuresList } from "./FeaturesList.js";

const features = featuresList;

export default function Features() {
  return (
    <Box>
      <Typography variant="h4" align="center" color="text.primary" gutterBottom>
        Features
      </Typography>
      <Container>
        <Grid container spacing={4}>
          {features.map((feature) => (
            <Grid item key={feature.id} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="div"
                  sx={{ pt: "70%" }}
                  image={feature.image}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6">
                    <strong>{feature.title}</strong>
                  </Typography>
                  <Typography>{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
