import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Navbar({ onContactClick }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar position={isMobile ? "sticky" : "relative"} sx={{ boxShadow: 1 }}>
      <Toolbar sx={{ bgcolor: "#ffebee" }}>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" alignItems="center" mb={isMobile ? 1 : 0}>
            <Typography
              color="#e53935"
              noWrap
              href="/"
              sx={{ fontSize: "1.5rem" }}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <strong>Big Red Warning</strong>
            </Typography>
            <Box
              component="span"
              sx={{ p: isMobile ? 1 : 2, textAlign: "center" }}
            >
              <Button variant="text" onClick={onContactClick}>
                <Typography noWrap color="#212121">
                  Contact
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
            <Button
              variant="contained"
              href="https://apps.apple.com/app/id6469755356"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ paddingX: 5, paddingY: 1.5, bgcolor: "#e53935" }}
            >
              <Typography color="#ffebee" sx={{ fontSize: "1.1rem" }}>
                <strong>Download now</strong>
              </Typography>
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
