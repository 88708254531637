export const featuresList = [
  {
    id: 1,
    title: "Using more than one screen?",
    image: "images/multi_monitor_vid.gif",
    description:
      "We got you! Big Red Warning follows your mouse cursor. No more missed notifications because they popped up on a screen you weren’t looking at!",
  },
  {
    id: 2,
    title: "Customizable calendar selection",
    image: "images/calendar_selection.jpg",
    description:
      "Every event isn't a red-alert scenario. Handpick the calendars you want to track. Keep the essentials, and ditch the noise. ",
  },
  {
    id: 3,
    title: "Easily adjust warning times",
    image: "images/lookahead_selection.jpg",
    description:
      "Need to change warning times during a busy day? No problem! Easily tweak your warning times straight from the menu bar.",
  },
];
