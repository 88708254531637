import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LinkedInContact() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        component="img"
        sx={{
          maxHeight: { xs: "12rem", md: "15rem" },
          maxWidth: { xs: "12rem", md: "15rem" },
        }}
        alt="profile photo"
        src="images/profile.jpg"
        borderRadius={"50%"}
      />
      <Button
        href="https://www.linkedin.com/in/junella-caringal/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography variant="h6" color="text.secondary">
          Junella Caringal
        </Typography>
      </Button>
      {isMobile ? (
        <Typography variant="body1" textAlign="center" marginX=".8rem">
          The San Francisco based developer behind
          <br />
          <strong style={{ color: "red" }}>Big Red Warning</strong>, written in
          Swift.
          <br />
          <br />
          Open to full-stack
          <br />
          software engineering opportunities
          <Button
            href="https://www.linkedin.com/in/junella-caringal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect on LinkedIn
          </Button>
        </Typography>
      ) : (
        <Typography
          variant="body1"
          textAlign="center"
          marginBottom={5}
          sx={{ maxWidth: "45rem" }}
        >
          The San Francisco based developer behind Big Red Warning, written in
          Swift.
          <br />
          Also has experience in Python, Typescript, React, MaterialUI, and
          Bootstrap.
          <br />
          <br />
          Open to opportunities and eager to connect with fellow tech
          enthusiasts and potential employers.
          <br />
          <Button
            href="https://www.linkedin.com/in/junella-caringal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect on LinkedIn
          </Button>
        </Typography>
      )}
    </Box>
  );
}
