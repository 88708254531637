import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function FinalSell() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginY={5}
      backgroundColor={isMobile ? "" : "#fcf1f080"}
      paddingY={isMobile ? 0 : 5}
    >
      <Box maxWidth={"md"}>
        {isMobile ? (
          <Box mx={1}>
            <Typography variant="h6" align="center" paragraph>
              Say goodbye to constant calendar checks and the anxiety of missed
              meetings with{" "}
              <strong style={{ color: "red" }}>Big Red Warning</strong>
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" align="center" paragraph>
              Say goodbye to constant calendar checks and the anxiety of missed
              meetings.
              <br />
              Immerse in what you're doing, and{" "}
              <strong style={{ color: "red" }}>Big Red Warning</strong> will
              alert you in time for that crucial meeting.
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            href="https://apps.apple.com/app/id6469755356"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ paddingX: 5, paddingY: 1.5, bgcolor: "#e53935" }}
          >
            <Typography color="#ffebee" sx={{ fontSize: "1.1rem" }}>
              <strong>Download now</strong>
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
