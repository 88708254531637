import "./App.css";
import React, { useRef } from "react";
import Navbar from "./components/Navbar";
import HeroContent from "./components/HeroContent";
import Features from "./components/FeatureComponents/Features";
import FinalSell from "./components/FinalSell";
import LinkedInContact from "./components/LinkedInContact";
import Footer from "./components/Footer";
import { Box } from "@mui/material";

export default function App() {
  const linkedInContactRef = useRef(null);
  const navigateToLinkedInContact = () =>
    linkedInContactRef.current.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <div>
      <Navbar onContactClick={navigateToLinkedInContact} />
      <HeroContent />
      <Features />
      <FinalSell />
      <Box ref={linkedInContactRef}>
        <LinkedInContact />
      </Box>

      <Footer />
    </div>
  );
}
