import { Box, Container, Grid, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        // backgroundColor: "#ffebee",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} container direction="column" alignItems="center">
            <Typography color="textSecondary" variant="subtitle1">
              {`${new Date().getFullYear()} | San Francisco, California`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
