import { Box, Container, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function HeroContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <main>
      <Box>
        <Container>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            marginTop={isMobile ? 6 : 0}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box
              maxWidth={"sm"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box>
                <Typography
                  variant="h6"
                  align="center"
                  paragraph
                  marginBottom={isMobile ? 0 : 4}
                >
                  Never miss a crucial meeting again!
                  <br />
                  <strong style={{ color: "red" }}>Big Red Warning</strong>{" "}
                  calendar notifications are impossible to miss. Stay focused,
                  stress-free.
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                visibility={isMobile ? "hidden" : "visible"}
              >
                <Button
                  href="https://apps.apple.com/app/id6469755356"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/download_mac.svg"
                    alt="Download on the App Store"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Button>
              </Box>
            </Box>
            <Box
              component="img"
              sx={{
                maxHeight: { xs: "20rem", md: "25rem" },
                maxWidth: { xs: "25rem", md: "50rem" },
              }}
              alt="app preview"
              src="images/heroImage.png"
              ml={isMobile ? 0 : 5}
              mt={isMobile ? 0 : 5}
              mb={5}
            />
          </Box>
        </Container>
      </Box>
    </main>
  );
}
